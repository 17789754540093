button {
  background: none;
  border: none;
}

.grid {
  display: grid;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.title_right_container {
  gap: 40px;
  margin-right: 20px;
  align-items: center;
}

.title_right_reload_button {
  justify-content: flex-end;
  align-items: center;
  color: var(--app-blue);
  padding-bottom: 2px;
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid transparent;
}

.title_right_reload_button:hover {
  background-color: #f7f7f7;
  border-color: var(--app-border-grey);
}

.map_zoom_control {
  flex-direction: column;
  display: flex;
  bottom: 0px !important;
  top: auto !important;
  right: 550px !important;
  margin-bottom: 10px;
}

.map_zoom_control_collapsed {
  right: 60px !important;
}

.map_color_indicator {
  margin-left: 10px;
  margin-bottom: 35px !important;
  left: 0px !important;
  right: auto !important;
}

.top_left_action_container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.top_right_action_container {
  position: absolute;
  top: 10px;
  margin-right: 10px;
  right: 550px;
  z-index: 2;
  gap: 10px;
  transition: 500ms ease-in-out;
}

.top_right_action_container_collapsed {
  right: 0px !important;
}

.top_right_action {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.search_type_container {
  width: 120px;
}

.search_type_textarea {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.search_container {
  width: 250px;
}

.search_textarea {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.search_no_result_container {
  min-height: 100;
}

.total_count_container {
  position: absolute;
  margin-top: 80px;
  margin-right: 10px;
  z-index: 1;
  overflow: hidden;
  right: 0px;
  top: 0px;
  transition: 500ms ease-in-out;
  padding: 10px 10px;
  width: 125px;
  height: 63px;
  opacity: 0.9;
  background: var(--app-white);
  border: 2px solid var(--app-border-grey);
  border-radius: 10px;
}

.total_count_number {
  font-weight: bold;
}

.divider {
  margin: 0;
  width: 100%;
  height: 1px;
  background-color: var(--app-border-grey);
  margin: 4px 0;
}

.divider_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.facility_name {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
}

.view_mode_container {
  width: 200px;
}

.information_collapsible_button {
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 50%;
  right: calc(550px - 15px);
  width: 30px;
  height: 30px;
  border-radius: 15px;
  color: var(--app-blue);
  background-color: white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4);
  z-index: 2;
  transition: 500ms ease-in-out;
  cursor: pointer;
}

.information_collapsible_button_collapsed {
  right: 5px;
  transition: 500ms ease-in-out;
}

.information_container {
  background-color: white;
  position: absolute;
  right: 0px;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  max-width: 550px;
  width: 550px;
  transition: width 500ms ease-in-out;
  z-index: 1;
  border-left: 1px solid var(--app-border-grey);
}

.information_container.information_container_collapsed {
  width: 0%;
  animation: fadeOut 500ms;
  animation-fill-mode: both;
}

.information_header {
  border-bottom: 1px solid var(--app-border-grey);
  padding: 15px 10px;
}

.information_title_container {
  padding: 5px 10px;
  align-items: center;
}

.information_title {
  font-size: 20px;
  font-weight: bold;
  color: var(--text-primary);
}

.status_counts {
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 20px 0px;
}

.status_pill_header {
  margin-left: 10px;
  padding: 0px 15px;
  border-radius: 20px;
  background-color: var(--status-yellow);
  color: white;
  font-weight: bold;
  text-align: center;
}

.status_pill {
  padding: 0px 15px;
  border-radius: 20px;
  background-color: var(--status-yellow);
  color: white;
  font-weight: bold;
  text-align: center;
  width: fit-content;
}

.information_tab {
  font-weight: bold;
  font-size: 14px;
  color: var(--app-blue);
  padding: 5px 20px;
  border-right: 1px solid var(--app-border-grey);
  cursor: pointer;
  letter-spacing: 0.5px;
}

.information_content {
  padding: 10px 20px;
  flex: 1;
}

.information_table {
  position: relative;
  flex: 1;
}

.paginator {
  margin-right: 14px;
}

.table_header {
  border-bottom: 1px solid var(--app-border-grey);
  flex: 1;
}

.table_column {
  flex: 1;
  padding: 10px 0px;
  align-items: flex-start;
  gap: 5px;
}

.table_column_status {
  flex: 1.5;
}

.table_column:first-child {
  padding-left: 20px;
}

.table_header_text {
  font-weight: bold;
}

.table_item {
  border-bottom: 1px solid var(--app-border-grey);
}

.interactable_table_item_text {
  color: var(--app-blue);
  text-decoration: underline;
  cursor: pointer;
  letter-spacing: 0.5px;
}

.table_item_text {
  color: #000000;
  letter-spacing: 0.5px;
}

.interactable_table_item_text:hover {
  font-weight: bold;
}

.link {
  color: var(--app-blue);
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.asset_data_value {
  font-size: 14;
  font-weight: 400;
  width: 100px;
  word-break: break-all;
}

.paginated_list_table_loading_container {
  padding: 0px 20px;
}

.no_result_message {
  text-align: center;
  color: var(--app-lightgrey);
  padding: 1rem;
  font-weight: 400;
  word-break: break-all;
  margin: auto;
}

.popup_title_container {
  font-weight: 600;
  font-size: 14;
  padding: 0px 5px 5px 0px;
  border-bottom: 1px solid var(--app-border-grey);
}

.popup_title_only {
  font-weight: 600;
  font-size: 14;
}

.popup_content_container {
  padding-top: 5px;
}

.popup_label {
  font-size: 12;
  padding: 0px 5px 5px 0px;
  font-weight: 400;
  display: inline;
}

.popup_value {
  font-weight: 600;
  font-size: 12;
  display: inline;
  margin-left: 4px;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.table_item_text_active {
  color: var(--app-blue);
  text-decoration: underline;
  cursor: pointer;
}

.table_item_text_active:hover {
  font-weight: bold;
  text-decoration: underline;
  color: var(--app-blue);
}

.tab_bar {
  border-bottom: 1px solid var(--app-border-grey);
  width: 100%;
}

.map_skeleton {
  height: 700px;
  width: 90%;
  margin: 8px;
}

.total_assets_text {
  margin-left: 15px;
  margin-top: 8px;
  font-weight: 400;
}

.total_assets_count {
  margin-left: 4px;
  font-weight: 700;
}

.loading_more_text {
  position: absolute;
  top: 18px;
  left: 20px;
  font-size: 16px;
  font-weight: bold;
  color: var(--app-lightgrey);
}
