.vertical_print_selection_container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: start;
}

.print_time_container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 10px 0;
}

.print_time_option_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

input[type="radio"] {
  accent-color: var(--app-blue);
  cursor: pointer;
}

.print_option_label {
  margin-top: 3px;
}

.dropdown_input_container {
  margin: 10px 0px;
  width: 240px;
}

.header {
  margin-bottom: 20px;
  font-weight: bold;
}

.sub_header {
  font-weight: bold;
}

.asterisk {
  color: var(--status-red) !important;
}

.horizontal_print_options {
  display: flex;
  flex-direction: row;
  gap: 75px;
  align-items: start;
}

.vertical_print_options {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  padding-right: 30px;
  border-right: 1.5px solid var(--app-border-grey);
}

.body_child_container {
  display: flex;
  flex-direction: column;
  min-width: 235px;
}

.info_card {
  margin: 10px 0;
}

.info_card_row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.info_card_row_label_container {
  width: 100px;
}

.info_card_row_value_container {
  display: flex;
  align-items: center;
  width: 120px;
}

.info_card_row_label {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0337788px;
  color: var(--text-primary);
}

.info_card_row_value {
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0337788px;
  color: var(--text-primary);
}

.status_icon {
  border-radius: 5px;
  width: 10px;
  height: 10px;
  align-self: center;
  margin-right: 10px;
}

.green {
  background-color: #6cb444;
}

.red {
  background-color: var(--status-red);
}

.skeleton_loading {
  display: flex;
  width: 140px;
  height: 20px;
}
