.dropdown_container {
  position: relative;
  width: 100%;
}

.dropdown_menu_container {
  position: absolute;
  background-color: transparent;
  padding-bottom: 30px; /* Add padding to make it harder for accidental clicks outside to close dropdown */
  width: 100%;
  z-index: 2147483647;
}

.dropdown_menu {
  margin: 0px;
  background-color: white;
  margin-top: 1px;
  padding: 0px;
  border: 1px solid var(--app-border-grey);
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  list-style: none;
  max-height: 300px;
  overflow-y: auto;
  width: fit-content;
  min-width: 100%;
  max-width: 235px;
}

.dropdown_item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  font-weight: 400;
}

.dropdown_item:hover {
  background-color: var(--app-offwhite);
}

.dropdown_no_result_message {
  text-align: center;
  color: var(--app-lightgrey);
  padding: 20px 8px;
  font-weight: 400;
  margin: auto;
}

.input_group {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input_group_hide_caret {
  caret-color: transparent;
  cursor: pointer;
}

.input_with_icon {
  overflow: hidden;
}

.input_with_search_icon {
  padding-left: 30px;
}

.search_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: var(--app-lightgrey);
  top: 50%;
  transform: translate(0, -50%);
  left: 10px;
}

.icon_container {
  display: flex;
  column-gap: 4px;
  position: absolute;
  right: 7px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--app-white);
}

.icon_container > svg {
  color: var(--app-blue);
  height: 24px;
  width: 24px;
  padding: 2px;
  border-radius: 33px;
  margin: auto;
}

.icon_container > svg:hover {
  opacity: 0.8;
  background-color: var(--app-border-grey);
}

.icon_container > svg:first-child {
  color: var(--text-secondary);
}

.icon_container > svg:first-child:hover {
  color: white;
}

.icon_container > svg:last-child {
  color: var(--app-blue);
}

.icon_container > svg:last-child:hover {
  color: var(--app-blue);
}

.error_message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
