.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.content {
  background-color: var(--app-white);
  padding: 2rem;
  border-radius: 8px;
  width: 400px;
}

.title {
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--text-primary);
}

.description {
  margin: 2rem 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
}

.description strong {
  font-weight: bold;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.button_outline {
  background-color: var(--app-offwhite);
  color: var(--text-primary);
}

.button_primary {
  background-color: var(--app-blue);
  color: var(--app-white);
}

.button:hover {
  opacity: 0.8;
}
