.table_options_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
}

.container {
  margin-right: 12px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 6px;
}

.date_range_button {
  cursor: pointer;
  background-color: var(--xemelgo-light-blue);
  color: var(--xemelgo-blue);
  padding: 4px 12px;
  font-size: 15px;
  font-weight: 700;
  border-radius: 3px;
  width: fit-content;
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.icon {
  transform: scale(0.8);
}

.table_container {
  border-radius: 3px;
  border: 1px solid var(--app-border-grey);
}

.header_row_container {
  border-width: 0px !important;
  padding: 6px 16px !important;
  background-color: var(--app-blue) !important;
  border-radius: 2px 2px 0 0;
}

.header_row_container * {
  font-size: 15px;
  font-weight: 700;
  color: white !important;
}

.table_header {
  padding: 0 !important;
}

.table_item_container {
  margin-bottom: 0;
  padding: 0 !important;
}

.table_item_row {
  padding: 8px 16px 8px 13px;
  border-width: 0;
  border-bottom-width: 1px;
  border-left: 4px solid transparent;
}

.table_item_row:last-child {
  border-radius: 0 0 3px 3px !important;
  border-bottom-width: 0;
}

.loading_table_item_row {
  border-width: 0;
  padding: 1.5px 16px;
}

.date_control {
  position: relative;
}

.date_range_container {
  position: absolute;
  top: 4px;
  z-index: 2;
}

.empty_list_container {
  border: 1px solid var(--app-border-grey);
  border-top-width: 0;
  border-radius: 0 0 3px 3px;
}

.status_popup_container {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  z-index: 1000;
}

.free_text_search_container {
  flex: 1;
}

.free_text_search_input {
  padding: 7px 30px;
}

.button {
  text-align: center;
  border-radius: 3px;
  padding: 5px 19px;
  font-weight: 700;
  background-color: var(--app-white);
  border: 1px solid var(--app-blue);
  color: var(--app-blue);
}

.button:hover {
  background-color: var(--xemelgo-light-blue);
}

.export_icon {
  margin-right: 10px;
}
