.top_section {
  display: flex;
}

.top_section_item {
  flex: 1;
}

.header_text {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2px;
}

.summary_container {
  margin-top: 30px;
  border-bottom: 1px solid var(--app-border-grey);
  max-height: 300px;
  overflow: auto;
}

.row {
  display: flex;
  flex-direction: row;
  column-gap: 14px;
}

.summary_table_row_left {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.summary_table_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  border-top: 1px solid var(--app-border-grey);
  font-size: 14px;
}

.secondary_text {
  font-weight: 400;
}

.bold_text {
  font-weight: 600;
}
