.container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  user-select: none;
}

.container:hover {
  opacity: 0.7;
}

.header_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.label {
  line-height: 18px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.sort_icons_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  margin-bottom: 3px;
  opacity: 0.7;
}

.sort_icons_container_active {
  opacity: 1;
}

.sort_icon {
  width: 12px;
  height: 8px;
  color: var(--text-secondary);
}

.sort_icon_active {
  color: var(--app-blue);
}
